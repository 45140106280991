<header class="header">
  <h1 class="logo-wrapper">
    <img
      class="logo"
      src="assets/mountain-mama-midwifery-logo.jpeg"
      alt="Mountain Mama Midwifery Logo"
    />
  </h1>
  <h2 class="description">Midwife in southern Vermont</h2>
</header>
<main class="main">
  <img
    class="main-image"
    src="assets/midwife-pool-homebirth.jpeg"
    alt="Mountain Mama Midwife Pool Homebirth"
  />
  <a class="email" href="mailto:mtnmamamidwife@gmail.com">
    mtnmamamidwife@gmail.com
  </a>
  <p class="heart">❤️</p>
</main>
